import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ElectoralLiteracy/2024/Classleader/1.jpg';
// import p2 from 'assests/Photos/ElectoralLiteracy/2024/Classleader/2.jpg';
// import p3 from 'assests/Photos/ElectoralLiteracy/2024/Classleader/3.jpg';
// import p4 from 'assests/Photos/ElectoralLiteracy/2024/Classleader/4.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';
import { Sidebar } from 'views/ElectoralLiteracy2023/components';

import Archive2023 from '../ElectoralLiteracy2023/components/Archive2023';
import Sidebar2024 from '../ElectoralLiteracy2023/components/Sidebar/Sidebar2024';



const ClassLeaderElection2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);
const p1 = `${config.base_image_url}/home/events-activities/clubs/electoral-literacy/2024/Classleader/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/clubs/electoral-literacy/2024/Classleader/2.webp`;
const p3 = `${config.base_image_url}/home/events-activities/clubs/electoral-literacy/2024/Classleader/3.webp`;
const p4 = `${config.base_image_url}/home/events-activities/clubs/electoral-literacy/2024/Classleader/4.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
   
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                CLASS LEADERS ELECTION 
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6 to 8 Date: 6 July 2024

                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                 {/* <br /> */}
                                 “Somewhere inside all of us is the power to change the world”

                            </Typography>


                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Election for class leaders of grades 6 to 8 was conducted on 6 th July 2024, in a democratic manner
                                by ballot paper method. The candidates had campaigned before the day of election. Rules were
                                announced beforehand in the morning assembly by the President and the Vice-president of the
                                Electoral club. Six booths were set up. Executives were appointed who managed the entire election
                                procedure. They checked the voter’s ID cards, gave the ballot paper and inked the left hand index
                                finger.
                                    <br></br>
                                The activity enabled the students to comprehend the procedures and the process followed on the day
                                of the election. The results were announced in the class by the class teacher.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “THE BALLOT IS STRONGER THAN BULLET”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 600}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2023/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassLeaderElection2024;